import { Container, VStack, Text, Grid, Link, Img, HStack, Box, Flex } from "@chakra-ui/react"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Footer from "../components/footer"
import NavBar from "../components/header"
import { SEO } from "../components/seo"
import ArizonaImage from '../images/state-images/Arizona.jpg'
import UtahImage from '../images/state-images/Utah.jpg'
import NevadaImage from '../images/state-images/Nevada.jpg'
import TexasImage from '../images/state-images/Texas.jpg'
import IdahoImage from '../images/state-images/Idaho.jpg'
import PDFIcon from '../images/pdf-icon.png'

const brochureMockData = [
  {
    location: "Arizona",
    link: 'https://assets.ctfassets.net/8baje2n8ckk1/onsuL6uI9LEgi8Nm8h7qt/00fc8fca099ca7ac90a4aec935f28915/AZ_Brochure_2025.pdf',
    renewalLink: 'https://assets.ctfassets.net/8baje2n8ckk1/7gdWkWVfQNgTY7hVdIUtBz/54d5e1fbabc0fa13da520517acc937a0/Renewal_Brochure_TX__NV_AZ.pdf',
    image: ArizonaImage
  },
  {
    location: "Utah",
    link: 'https://assets.ctfassets.net/8baje2n8ckk1/3IXTkaXqu6fSgMj5VeeRAu/4ae5588d1e30bc8e82cf47b835ec225f/UT_Brochure_2025.pdf',
    renewalLink: 'https://assets.ctfassets.net/8baje2n8ckk1/31cFGbofIwnTbrYZFvlU6a/cdebac3d8f77a83984c9e676b7c7c5f9/Renewal_Brochure_UT-ID.pdf',
    image: UtahImage
  },
  {
    location: "Nevada",
    link: 'https://assets.ctfassets.net/8baje2n8ckk1/64xSOh2juDw7UZckMppIEH/c4ae1618a655497ede36082af1fd6c46/NV_Brochure_2025.pdf',
    renewalLink: 'https://assets.ctfassets.net/8baje2n8ckk1/7gdWkWVfQNgTY7hVdIUtBz/54d5e1fbabc0fa13da520517acc937a0/Renewal_Brochure_TX__NV_AZ.pdf',
    image: NevadaImage
  },
  {
    location: "Texas",
    link: 'https://assets.ctfassets.net/8baje2n8ckk1/32Ff2y3DM0MxVnL2Bjic8l/e254bb87238a853e3b1567cf90980862/TX_Brochure_2025.pdf',
    renewalLink: 'https://assets.ctfassets.net/8baje2n8ckk1/7gdWkWVfQNgTY7hVdIUtBz/54d5e1fbabc0fa13da520517acc937a0/Renewal_Brochure_TX__NV_AZ.pdf',
    image: TexasImage
  },
  {
    location: "Idaho",
    link: 'https://assets.ctfassets.net/8baje2n8ckk1/2uzDnxhVTMTBEmL5MBx4QS/b2d4a4dde6cf8be480d95aed6b72a58b/ID_Brochure_2025.pdf',
    renewalLink: 'https://assets.ctfassets.net/8baje2n8ckk1/31cFGbofIwnTbrYZFvlU6a/cdebac3d8f77a83984c9e676b7c7c5f9/Renewal_Brochure_UT-ID.pdf',
    image: IdahoImage
  },
]

const BrochuresPage = (props: any) => <>
  <SEO title="Brochures | Acclaimed Home Warranty"
    description="Acclaimed Home Warranty - Brochures" />
  <NavBar />
  <main>
    {/** Banner */}
    <div className="w-full" style={{ overflow: "visible" }}>
      <Container maxW={['full', '7xl']} px={[4, 6, 8]} pt="0" position="relative">
        <Flex w="full" justifyContent="center" alignItems="center" position="relative" py={[4, 6, 8]}>
          <Box w="300px" h="300px" display={{ base: "none", md: "block" }} background="#81e1e5" borderRadius="100%" position="absolute" bottom="-35px" left="-40px" zIndex="-100">
          </Box>
          <Flex direction="row" alignItems="center" justifyContent="center" width="full">
            <Box w={{base:"80px", sm:"120px", md:"180px", lg: "250px"}} mr={[2, 3, 4]}>
              <StaticImage src="../images/norman-read.png" alt="AcclaimedHW contact us" />
            </Box>
            <Text textAlign="center" fontWeight="bold" fontSize={{ base: '3xl', sm:'4xl', md: '5xl', lg: '7xl' }} textColor="#beeb9e" fontFamily="'League Spartan', sans-serif">Brochures</Text>
          </Flex>
          <Box w="300px" h="300px" display={{ base: "none", md: "block" }} background="#81e1e5" borderRadius="100%" position="absolute" bottom="40px" right="-50px" zIndex="-100">
          </Box>
        </Flex>
      </Container>
      <Box w="full" h="10" background="#01a1ba" py="2">
      </Box>
    </div>
    <Container maxW={['full', 'lg', '4xl', '6xl']} px={[4, 6, 8]} my={[8, 12, 16]}>
      <Grid 
        templateColumns={{ 
          base: "repeat(2, 1fr)", 
          sm: "repeat(2, 1fr)", 
          md: "repeat(3, 1fr)", 
          lg: "repeat(5, 1fr)" 
        }} 
        gap={[4, 6, 8]} 
        mt="3"
        justifyItems="center"
      >
        {brochureMockData.map((data) => (
          <VStack 
            key={data.location} 
            spacing={[2, 3, 4]}
            mb={[6, 8, 10]}
            p={[2, 3, 4]}
            borderRadius="md"
            _hover={{ boxShadow: "md", transform: "translateY(-5px)" }}
            transition="all 0.3s ease"
            alignItems="center"
            width="full"
            maxW={{base: "140px", sm: "180px", md: "220px", lg: "250px"}}
          >
            <Box 
              w={["70px", "80px", "90px", "100px"]} 
              h={["70px", "80px", "90px", "100px"]}
              overflow="hidden"
            >
              <Img src={data.image} w="full" h="full" objectFit="cover" />
            </Box>
            <Text 
              textAlign="center" 
              py={[1, 2, 3]} 
              fontSize={['lg', 'xl', '2xl']} 
              fontWeight="medium"
            >
              {data.location}
            </Text>
            <Link 
              target="_blank" 
              href={data.link} 
              display="flex" 
              flexDirection="column" 
              alignItems="center" 
              textAlign="center"
              mb={3}
              _hover={{ textDecoration: "none", color: "#01a1ba" }}
            >
              <Box w={["60px", "80px", "100px", "120px"]} mb={2}>
                <img src={PDFIcon} alt="PDF Icon" width="100%" />
              </Box>
              <Text fontSize={{base: "sm", md: "md"}}>Pricing/Options</Text>
            </Link>
            <Link 
              target="_blank" 
              href={data.renewalLink} 
              display="flex" 
              flexDirection="column" 
              alignItems="center" 
              textAlign="center"
              _hover={{ textDecoration: "none", color: "#01a1ba" }}
            >
              <Box w={["60px", "80px", "100px", "120px"]} mb={2}>
                <img src={PDFIcon} alt="PDF Icon" width="100%" />
              </Box>
              <Text fontSize={{base: "sm", md: "md"}}>Renewal Pricing</Text>
            </Link>
          </VStack>
        ))}
      </Grid>
    </Container>
  </main>
  <Footer />
</>

export default BrochuresPage
